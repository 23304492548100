import {
  SEARCH_DEPLOYMENT_ID,
  TRENDING_SEARCHES_IDS,
  TRENDING_SEARCHES_NO_RESULTS_IDS,
  PDP_YOU_MAY_ALSO_LIKE_ID,
  CART_YOU_MAY_ALSO_LIKE_IDS,
} from '@integration-layer/data/xGenIds'
import snakeCase from 'lodash/snakeCase'

export const getCarouselNameID = (carouselID: string) => {
  if (!carouselID) return 'unmapped'

  const predictionToNameMap = new Map<string, string>([
    [SEARCH_DEPLOYMENT_ID, 'search_deployment'],
    [PDP_YOU_MAY_ALSO_LIKE_ID, 'product_detail_page_you_may_also_like'],
    ...(TRENDING_SEARCHES_IDS.map(item => [
      item.predictionId,
      snakeCase('trending_searches_ids_' + item.name),
    ]) as [string, string][]),
    ...(TRENDING_SEARCHES_NO_RESULTS_IDS.map(item => [
      item.predictionId,
      snakeCase('trending_searches_no_results_' + item.name),
    ]) as [string, string][]),
    ...(Object.entries(CART_YOU_MAY_ALSO_LIKE_IDS).map(([key, value]) => [
      value,
      snakeCase('cart_you_may_also_like_ids_' + key),
    ]) as [string, string][]),
  ])

  return predictionToNameMap.get(carouselID) ?? ''
}
