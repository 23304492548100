import { z } from 'zod'
import { itemSchema } from '.'

export const viewItemListSchema = z.object({
  event: z.literal('view_item_list'),
  eventID: z.literal('001'),
  ecommerce: z.object({
    item_list_id: z.string(),
    item_list_name: z.string(),
    items: z.array(itemSchema),
  }),
})
