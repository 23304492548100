import type { z } from 'zod'
import type { AlgoliaProductType } from '@integration-layer/modules/05.productServices/runtime/components/WrapperProductTile.props.ts'
import { viewItemListSchema } from '@integration-layer/schemas/GAEvents/viewItemList'
import snakeCase from 'lodash/snakeCase'
import { getCarouselNameID } from '@integration-layer/utils/XGenCarouselMap'

type ViewItemList = z.infer<typeof viewItemListSchema>
type BooleanLiteral = 0 | 1

export const useGAxGenViewItemListEvent = (
  XGenRecommendations: Array<AlgoliaProductType>,
  carouselID: string,
  options?: { waitForDatalayerSetup: boolean }
) => {
  try {
    if (
      XGenRecommendations.length === 0 ||
      !Array.isArray(XGenRecommendations)
    ) {
      console.error('Invalid or missing XGenRecommendations array.')
      return
    }

    const { latestSelectedBrand } = useSearchLatestBrandSelection()

    const carouselNameID = getCarouselNameID(carouselID)

    const isTrendingSearchesCarousel = carouselNameID.includes(
      'trending_searches_ids_'
    )

    const availableItem = XGenRecommendations.map((item, index) => {
      const item_categories = item.microCat?.split(' > ') ?? ['']

      const item_category2 = item_categories[item_categories.length - 1]

      const item_brand =
        item.Brand.toLowerCase() === 'ea7' ? 'EA7' : snakeCase(item.Brand)

      const firstAvailableItem = item?.size.find(size => size?.avEcom ?? 0 > 0)

      const selectedSize = firstAvailableItem?.avEcom
        ? {
            size: firstAvailableItem.label,
            quantity: firstAvailableItem?.avEcom > 0 ? 1 : 0,
          }
        : { size: '', quantity: 0 }

      return {
        affiliation: 'armani.com',
        coupon: '',
        discount: 0 as BooleanLiteral,
        index: index + 1,
        item_availability:
          selectedSize.quantity > 0
            ? (1 as BooleanLiteral)
            : (0 as BooleanLiteral),
        item_brand: item_brand ?? '',
        item_category: snakeCase(item?.macroCat) ?? '',
        item_category2: snakeCase(item_category2) ?? '',
        item_category4: snakeCase(item.Gender) ?? '',
        item_discount: 0 as BooleanLiteral,
        item_id: item.objectID ?? '',
        item_img: item.assets?.[0].URL ?? '',
        item_list_id: '',
        item_list_name: snakeCase(`${item.Brand}-${item_category2}`) ?? '',
        item_mfc_id: item.objectID.replaceAll('_', '') ?? '',
        item_name: item.Name ?? '',
        item_reservable: 0 as BooleanLiteral,
        item_size: selectedSize.size ?? '',
        item_variant: item.ColorDesc ?? '',
        price: item.FullPrice ?? 0,
        quantity: selectedSize.quantity ?? 0,
      }
    }).filter(item => item !== null)

    if (!isTrendingSearchesCarousel) {
      const event: ViewItemList = {
        event: 'view_item_list',
        eventID: '001',
        ecommerce: {
          item_list_id: carouselID ?? '',
          item_list_name: carouselNameID,
          items: availableItem,
        },
      }

      useGASendEvent(viewItemListSchema, event, {
        clearEcommerce: true,
        waitForDatalayerSetup: options?.waitForDatalayerSetup,
      })
    } else if (
      carouselNameID.includes(snakeCase(latestSelectedBrand.value)) ||
      latestSelectedBrand.value.includes('all')
    ) {
      const chunkedProductList = chunk(availableItem, 20)
      chunkedProductList.forEach(chunk => {
        const trendingSearchesEvent: ViewItemList = {
          event: 'view_item_list',
          eventID: '001',
          ecommerce: {
            item_list_id: carouselID ?? '',
            item_list_name: carouselNameID,
            items: chunk,
          },
        }

        useGASendEvent(viewItemListSchema, trendingSearchesEvent, {
          clearEcommerce: true,
          waitForDatalayerSetup: options?.waitForDatalayerSetup,
        })
      })
    }
  } catch (error) {
    console.error('Error in useGAViewItemListEvent:', error)
  }
}
