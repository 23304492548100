export const useSearchLatestBrandSelection = () => {
  const latestSelectedBrand = useGAEntity<string>('GA_SEARCH_BRAND_SELECTED')

  const updateLatestSelectedBrand = (brand: string) => {
    latestSelectedBrand.value = brand
  }

  return {
    latestSelectedBrand,
    updateLatestSelectedBrand,
  }
}
