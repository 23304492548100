<script setup lang="ts">
import type { XGenRecommendationsProps } from './XGenRecommendations.props.ts'

const props = defineProps<XGenRecommendationsProps>()

const { recommendations } = useXgen()
const { skuPrices, fetchSkuPrices } = useProductTileClPrices()
const showMore = ref(false)

const { data: products, status } = useAsyncData(
  `xgen-recommendations-${props.prediction}`,
  () => {
    return recommendations({
      predictionID: props.prediction,
    })
  },
  {
    default: () => [],
    server: false,
  }
)

const productItemsDefaultSkuCodes = computed(() => {
  return (
    products.value
      ?.map(item => item.size?.at(0)?.SKU)
      ?.filter(item => isNonNullable(item)) ?? []
  )
})

const productsWithCommerceLayerPrices = computed(() => {
  return (
    products.value?.map(product => {
      return {
        ...product,
        clPrices: skuPrices.value?.[product.size[0].SKU ?? ''],
      }
    }) ?? []
  )
})

if (import.meta.client) {
  watch(
    productItemsDefaultSkuCodes,
    productItemsDefaultSkuCodes => {
      if (productItemsDefaultSkuCodes.length) {
        useGAxGenViewItemListEvent(
          products.value?.slice?.(0, props.initialClFetch) ?? [],
          props.prediction
        )
        fetchSkuPrices(
          productItemsDefaultSkuCodes.slice(0, props.initialClFetch)
        )
      }
    },
    {
      once: true,
    }
  )
}

const loadMore = () => {
  fetchSkuPrices(productItemsDefaultSkuCodes.value)
  showMore.value = !showMore.value
  useGAxGenViewItemListEvent(products.value ?? [], props.prediction)
}
</script>
<template>
  <div class="contents">
    <slot
      :products="productsWithCommerceLayerPrices"
      :load-more="loadMore"
      :status="status"
      :show-more="showMore"
    />
  </div>
</template>
