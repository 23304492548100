<script setup lang="ts">
import type { WrapperProductTileProps } from './WrapperProductTile.props'
import { useWrapperProductTile } from '../composables/useWrapperProductTile'

const props = withDefaults(defineProps<WrapperProductTileProps>(), {
  id: '',
  'data-testid': '',
})

const { normalizeProduct, transformToProductCardHorizontal } =
  useWrapperProductTile()

const normalizedProduct = computed(() => normalizeProduct(props.product))

const transformedProductCardHorizontal = computed(() => {
  if (!normalizedProduct.value) return
  return transformToProductCardHorizontal(normalizedProduct.value)
})
</script>

<template>
  <div class="contents">
    <slot
      v-if="normalizedProduct && transformedProductCardHorizontal"
      :product="normalizedProduct"
      :product-card-horizontal="transformedProductCardHorizontal"
    />
  </div>
</template>
